<template>
  <div class="redirects__list">
    <v-row no-gutters class="mb-12">
      <v-col cols="12" md="6" sm="6">
        <redirect class="my-5" title="Navigation" route="website.menus" />
        <div>
          <!-- <v-btn>Export</v-btn> -->
          <!-- <v-btn @click="modal = true">Import</v-btn> -->
          <v-btn outlined color="primary" class="mr-2" @click="modal = true"
            ><i class="fas fa-download mr-1"></i>{{ $t('Import') }}
          </v-btn>
          <v-btn outlined color="primary" @click="modalExport = true"
            ><i class="fas fa-file-export mr-1"></i>{{ $t('Export') }}
          </v-btn>
        </div>
      </v-col>
      <v-col class="d-flex align-center justify-end" cols="12" md="6" sm="6">
        <v-btn @click="$router.push({ name: 'website.redirects.create' })" color="primary">
          Create URL redirect
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="elevation-form px-5 py-5" cols="12" md="12" sm="12" xs="12">
        <Resource
          :document="document"
          :searchable="searchableField"
          :columns="columns"
          :update-router-callback="getUpdateRouterData"
          :callback-action="getAction"
          :callback-selection="getIds"
          :actionItems="items"
        />
        <v-col cols="12" class="d-flex justify-space-between">
          <limit-page :callback="setLimit" />
          <pagination v-if="document && document.length > 0" :page.sync="page" :length="lengthPage"></pagination>
        </v-col>
      </v-col>
    </v-row>
    <modal width="600px" model="modalExport" title="Export orders" :callback="submitExportCSV">
      <template slot="content">
        <v-row>
          <v-col>
            <!-- <div>
              <h3>Store</h3>
              <v-autocomplete
                v-model="value"
                :items="items"
                dense
                solo
                background-color="#e6e6e6"
                label="Filled"
              ></v-autocomplete>
            </div> -->
            <div>
              <h3>Export</h3>
              <v-radio-group v-model="radioGroup">
                <!-- <v-radio value="current-page" label="Current page"></v-radio>
                <v-radio value="order-by-date" label="Order by date"></v-radio> -->
                <v-radio @click="selectAll" value="all-products" label="All orders"></v-radio>
                <!-- <v-radio value="selected-0-order" disabled label="Selected 0 order"></v-radio> -->
                <v-radio @click="selectCurent" value="curent-search" label="Current search"></v-radio>
              </v-radio-group>
            </div>
          </v-col>
          <!-- <v-col>
            <div>
              <h3>Export as:</h3>
              <v-radio-group>
                <v-radio value="csv" label="CSV for Excel, Numbers, or other spreadsheet programs"></v-radio>
                <v-radio value="order-by-date" label="Plain csv file"></v-radio>
              </v-radio-group>
            </div>
            <div>
              <h3>Export template:</h3>
              <v-radio-group>
                <v-radio value="" label="Orders"></v-radio>
                <v-radio value="" label="Transaction histories"></v-radio>
                <v-radio value="" label="Customize export fields"></v-radio>
              </v-radio-group>
            </div>
          </v-col> -->
        </v-row>
      </template>
    </modal>
    <!-- <v-card v-if="!document || document.length === 0">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12" sm="12" class="text-center">
            <img class="mx-auto" src="@/assets/images/redirects-url.svg" alt="" />
            <h2>Manage your URL redirects</h2>
            <p>Prevent old links from breaking by redirecting your customers to another page.</p>
            <div class="d-flex justify-center">
              <v-btn @click="$router.push({ name: 'website.redirects.create' })" class="mr-3" color="primary">
                Create URL redirect
              </v-btn>
              <v-btn color="" @click="modal = true">Import redirects</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card> -->
    <modal title="Import redirects" model="modal" :callback="submit">
      <template slot="content">
        <div>
          <v-alert v-if="!fileErrors || fileErrors.length == 0" type="warning">
            Note: Please use this required

            <a
              style="color:#4181cb"
              href="https://s3-us-west-2.amazonaws.com/assets.shopbase.com/import-file-sample/redirects.csv"
              target="_blank"
              >sample CSV template</a
            >

            CSV template to create your list of redirects.
          </v-alert>
          <v-file-input @change="onChangeFile" v-model="file"></v-file-input>
          <!-- <notification v-if="fileErrors && fileErrors.length > 0" type="error" :messages="fileErrors" /> -->
          <!-- <file-uploader v-on:callback="onFileUploaded" /> -->
        </div>
      </template>
    </modal>
    <v-dialog persistent v-model="showModalDelete" width="500" style="transform-origin: center top">
      <v-card relative>
        <!-- Title -->
        <v-card-title class="headline"> {{ 'Delete' }} {{ ids.length }} items?. </v-card-title>
        <div class="close-icon" @click="showModalDelete = false">
          <v-icon>fas fa-times</v-icon>
        </div>
        <v-divider class="mb-3"></v-divider>
        <v-card-text class="">
          Are you sure you want to delete {{ ids.length }} items. This action cannot be reversed.
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" @click="showModalDelete = false"> Cancel </v-btn>
          <v-btn :loading="isLoadingButton" color="error" @click="onSubmitDelete"> Delete </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Redirect from '@/components/RedirectTo';
import Modal from '@/components/Modal';
import FileUploader from '@/components/form/FileUploader';
import Notification from '@/components/Notification';
import Resource from '@/components/Resources';
import resourceListMixin from '@/mixins/resource-list';
import { shortlinkApi } from '@/apis/shortlink';

export default {
  components: {
    Redirect,
    Modal,
    // FileUploader,
    // Notification,
    Resource,
  },
  mixins: [resourceListMixin],
  data() {
    return {
      radioGroup: '',
      modalExport: false,
      modal: false,
      file: null,
      fileErrors: null,
      resourceType: 'shortlinks',
      items: [{ title: 'Delete selected', action: 'remove' }],
      ids: [],
      limit: 20,
      columns: {
        Selectable: {
          blankLabel: true,
          kind: 'selectable',
          width: 1,
        },
        ShortLink: {
          field: 'shortLink',
          kind: 'title',
        },
        FullLink: {
          field: 'fullLink',
          kind: 'text2',
        },
      },
      searchableField: 'search',
      sortable: [],
      showModalDelete: false,
      isLoadingButton: false,
      results: [],
      updateRouteName: 'website.redirects.update',
    };
  },
  methods: {
    selectAll() {
      this.params = {};
    },
    selectCurent() {
      // console.log(this.searchOrder);
      // this.params.curentSearch = this.$router.app._route.query._query;
      if (this.searchOrder != '') {
        this.params.search = this.searchOrder;
      }
      if (this.$route.query._query) {
        let queries = JSON.parse(this.$route.query._query);
        queries.forEach(q => {
          if (q.compare) {
            this.params[q.field] = q.value;
            this.params[q.fieldCompare] = q.compare;
          } else {
            this.params[q.field] = Array.isArray(q.value) ? q.value.join(',') : q.value;
          }
        });
      }
      console.log(this.params);
    },
    async onSubmitDelete() {
      try {
        await shortlinkApi.deleteByIds(this.ids.join(','));
        this.showModalDelete = false;
        window.location.reload();
      } catch (error) {
        this.showModalDelete = false;
        console.log(error);
      }
    },
    getAction(action, ids) {
      if (action.action === 'remove') {
        this.ids = ids;
        this.showModalDelete = true;
        // let deleteIds = ids.join(',');
      }
    },
    getIds(ids) {},
    onFileUploaded(fileResources) {
      this.file = fileResources && fileResources.file.length > 0 ? fileResources.file[0] : null;
      this.fileErrors = fileResources.errors;
    },
    async csvJSON(csv) {
      var vm = this;
      var lines = vm.$papa.parse(csv, {});
      let count = lines.data.length - 1;
      lines.data.map(function(line, indexLine) {
        if (indexLine < 1) return; // Jump header line
        if (line && line.length > 0) {
          var obj = {};
          if (line.length > 1) {
            obj.shortLink = line[0] || '';
            obj.fullLink = line[1] || '';
            vm.results.push(obj);
          }
        }
      });
    },
    onChangeFile() {
      // var lines = this.$papa.parse(this.file, {});
      var vm = this;
      if (window.FileReader) {
        var reader = new FileReader();
        // Handle errors load
        reader.onload = function(event) {
          var csv = event.target.result;
          vm.parse_csv = vm.csvJSON(csv);
        };
        reader.readAsText(this.file, 'UTF-8');
        reader.onerror = function(evt) {
          if (evt.target.error.name == 'NotReadableError') {
            alert(`Canno't read file !`);
          }
        };
      } else {
        alert('FileReader are not supported in this browser.');
      }
      // console.log(lines);
    },
    async submit() {
      if (this.results.length > 0) {
        try {
          await shortlinkApi.bulk(this.results);
          this.modal = false;
          window.location.reload();
        } catch (error) {
          console.log(error);
        }
      }
    },
    downloadFile(fileName, urlData) {
      var aLink = document.createElement('a');
      aLink.download = fileName;
      aLink.href = urlData;
      var event = new MouseEvent('click');
      aLink.dispatchEvent(event);
    },
    async submitExportCSV() {
      // let data = await orderApi.getExport(this.params);
      // console.log(data);
      // this.downloadFile('export.csv', 'data:text/csv;charset=UTF-8,' + encodeURIComponent(data.data));
      this.modalExport = false;
      this.radioGroup = '';
    },
  },
};
</script>
